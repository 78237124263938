<template>
  <div>
    <admin-title></admin-title>

    <div class="container">
      <div class="page-title">
        <span>ペルソナ</span>
      </div>

      <div class="page-title-two">
        <span>ペルソナ</span>
      </div>

      <div class="info">
        <img src="../../assets/images/persona/header.png">
        <div class="input-list">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="12.422rem" class="demo-ruleForm">
            <el-form-item class="item" label="名前" prop="name">
              <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item class="item" label="性別" prop="name">
              <el-select v-model="ruleForm.name" placeholder="请选择性別">
                <el-option label="男性" value="男性"></el-option>
                <el-option label="女性" value="女性"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="item" label="年齢層" prop="name">
              <el-select v-model="ruleForm.name" placeholder="请选择年齢層">
                <el-option label="17~19歳" value="17~19歳"></el-option>
                <el-option label="20~23歳" value="20~23歳"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="item" label="居住地" prop="name">
              <el-select v-model="ruleForm.name" placeholder="请选择居住地">
                <el-option label="东京" value="东京"></el-option>
                <el-option label="大阪" value="大阪"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="item" label="居住地" prop="name">
              <el-select v-model="ruleForm.name" placeholder="请选择居住地">
                <el-option label="东京" value="东京"></el-option>
                <el-option label="大阪" value="大阪"></el-option>
              </el-select>
            </el-form-item>



            <!--            <el-form-item>-->
            <!--              <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
            <!--              <el-button @click="resetForm('ruleForm')">重置</el-button>-->
            <!--            </el-form-item>-->
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "persona",

  data() {
    return {
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        region: [
          {required: true, message: '请选择活动区域', trigger: 'change'}
        ],
        date1: [
          {type: 'date', required: true, message: '请选择日期', trigger: 'change'}
        ],
        date2: [
          {type: 'date', required: true, message: '请选择时间', trigger: 'change'}
        ],
        type: [
          {type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change'}
        ],
        resource: [
          {required: true, message: '请选择活动资源', trigger: 'change'}
        ],
        desc: [
          {required: true, message: '请填写活动形式', trigger: 'blur'}
        ]
      }
    }
  },

  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },


    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    changeIsAdd() {
      this.isAdd = !this.isAdd;
    }
  }

}
</script>

<style scoped src="../../style/persona.css">

</style>
